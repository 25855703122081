import { InputBase, styled } from "@mui/material";

export const UnstyledInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      position: 'relative',
      backgroundColor: 'transparent',
      color: '#747474',
      border: 'none',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      fontFamily: [
        'Barmeno',
        'sans-serif',
      ].join(','),
      '&:focus': {
        color: '#1d1d1d'
      },
      '&::hover': {
        color: '#1d1d1d'
      }
    },
  }));