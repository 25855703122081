import { SpiceLevelType } from "./MenuPageController";
import {spice1, spice3, spice2, bbq, lime, veg, plant_based, non_veg} from "./assets";

export const SpiceLevel :SpiceLevelType[] = [
  {
    "id": 1,
    "img": spice3,
    "title": 'Very Hot',
    "value": "very_hot",
    "description": "Don’t say we didn’t warn you"
  },
  {
    "id": 2,
    "img": spice2,
    "title": 'Medium',
    "value": "medium",
    "description": "A bit of kick but nothing you can’t handle"

  },
  {
    "id": 3,
    "img": spice1,
    "title": 'Mild',
    "value": "Mild",
    "description": "Zesty and fresh lemon & herbs marinade"
  },
  {
    "id": 4,
    "img": bbq,
    "title": 'BBQ Peri Peri',
    "value": 'BBQ Peri Peri',
    "description": "Tasty BBQ with a bit of a bite"
  },
  {
    "id": 5,
    "img": lime,
    "title": 'Mango & Lime',
    "value": 'Mango&Lime',
    "description": "Fresh mango flavour with tangy lime"
  }
]

export const dietType :SpiceLevelType[] = [
  {
    "id": 1,
    "img": veg,
    "title": 'Vegetarian',
    "value": 'vegetarian'
  },
  {
    "id": 2,
    "img": plant_based,
    "title": 'Plant-based',
    "value":"plant_based"
  },
  {
    "id": 3,
    "img": non_veg,
    "title": 'Non-Vegetarian',
    "value":"non_vegetarian"
  },
]