import React from "react";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import Chip from "../components/Chip";
import { collectionIcon, deliveryIcon } from "../../../../../blocks/dashboard/src/assets";

interface Props {
    actionCell: (info: CellContext<unknown, any>) => JSX.Element;
}

export const storeAdminColumns = ({actionCell}: Props) => {
    const columns: ColumnDef<unknown, any>[] = [
        {
            header: "Order Type",
            accessorKey: "order_type",
            cell: info => {
                switch(info.getValue()) {
                    case "delivery":
                        return <Chip
                            style={{ backgroundColor: "#D1FAE5" }}
                            icon={<img src={deliveryIcon} alt="delivery" />}
                            label={info.getValue().charAt(0).toUpperCase() + info.getValue().slice(1)}
                        />;
                    case "collection":
                        return <Chip
                            style={{ backgroundColor: "#FEF3C7" }}
                            icon={<img src={collectionIcon} alt="collection" />}
                            label={info.getValue().charAt(0).toUpperCase() + info.getValue().slice(1)}
                        />;
                    default:
                        return <Chip
                            label={info.getValue().charAt(0).toUpperCase() + info.getValue().slice(1)}
                        />;
                }
            },
        },
        {
            header: "Order Status",
            accessorKey: "status",
            cell: info => info.getValue().charAt(0).toUpperCase() + info.getValue().slice(1),
        },
        {
            header: "Order Number",
            accessorKey: "order_number",
            cell: info => "#" + info.getValue(),
        },
        {
            header: "Items",
            accessorKey: "items_count",
            cell: info => info.getValue() + "x",
        },
        {
            header: "Amount",
            accessorKey: "total",
            cell: info => `£${info.getValue()}`,
        },
        {
            header: "Action",
            accessorKey: "action",
            cell: info => actionCell(info),
        },
    ];

    return columns;
}