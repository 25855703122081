import React from 'react'
import "./ProductDetails.css"
import OrderHistoryController, { OrderHistoryOrders, Props } from './OrderHistoryController';
import Profile from '../../../components/src/Profile';
import { collectionHead, deliveryHead, imageEuroSign, imageNotes, imageRestaurantSign, noOrderHistory, noOrderHistoryResultFound} from './assets';
import { Accordion, AccordionSummary, Button } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { messageCart } from '../../../blocks/shoppingcart/src/assets';
import ImageSeriesStatusOrderHistory from '../../../components/src/ImageSeriesStatusOrderHistory.web';
import DialogPopupWeb from '../../../components/src/DialogPopup.web';
import DialogPopupTasks from '../../../components/src/DialoguePopupTasks.web';
import Task from '../../../blocks/tasks/src/Task.web';
import "./OrderHistory.web.css";
import ViewOrderDetails from './../../../components/src/ViewOrderDetails/ViewOrderDetails.web';
import Search from '../../../blocks/search/src/Search';
import { formatDateandTime } from './../../../components/src/utils';


export default class OrderHistory extends OrderHistoryController {
    constructor(props: Props) {
        super(props);
    }

    accordianToBeshown = (activeOrNot: string, order_history:OrderHistoryOrders[]) => {
        return (
            <Accordion
                expanded={this.state.expandAccordian === activeOrNot}
                onChange={this.handleExpand(activeOrNot)}
                style={{ borderRadius: "8px", margin: "12px 0px", fontWeight: "600", fontSize: "16px", color: "#1D1D1D" }} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="accordian_spicelevel_summary"
                    id="accordian_spicelevel_summary"
                    style={{
                        fontFamily: "Barmeno",
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#1D1D1D",
                        padding: "16px",
                    }}
                >
                    {activeOrNot} Orders
                </AccordionSummary>
                {order_history.length >= 1 ? this.orderHistoryData(activeOrNot,order_history) :
                    this.noOrderHistoryFound(activeOrNot)}
            </Accordion>
        )
    }

    noOrderHistoryFound = (activeOrNot: string) => {
        return (
            <div style={{ backgroundColor: "#F8FAFC", display: "flex", justifyContent: "center", alignItems: "center", height: "460px", boxSizing: "border-box" }}>
                <img src={(noOrderHistory)} alt='no-order-history' />
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "16px", fontFamily: "Barmeno", fontSize: "20px", fontWeight: 700 }}>
                    <div>No {(activeOrNot.toLowerCase())} orders here!  </div>
                    <div id="content" >Time to dive in and start your Wingo's feast now!</div>
                    <Button id='buttonTestId' onClick={this.navigateToMenuPage} style={{ color: "#ffffff", backgroundColor: "#E11B22", width: '250px', height: '56px', padding: '16px 0px 0px 0px', borderRadius: '8px', fontWeight: 500, fontSize: "16px" }}>
                        ORDER NOW!
                    </Button>
                </div>
            </div>
        )
    }

    orderHistoryData = (activeOrNot: string, order_histories:OrderHistoryOrders[]) => {
        return (
            <div className='order_history_card_container'>
                {order_histories.map((orderHistory: OrderHistoryOrders, index: number) => {
                    const orderStatus = orderHistory.attributes.status;
                    return (
                        <div key={index} className='order_history_card'>
                            <div className='order_number_dates'>
                                <div className='order_details_summary'>
                                    <div>#{orderHistory.attributes.order_number}</div>
                                    <div className='order_time_detail'>Ordered on: {formatDateandTime(orderHistory.attributes.placed_at)}</div>
                                </div>
                                <img src={orderHistory.attributes.order_type === 'delivery' ? deliveryHead : collectionHead} alt='delivery' />
                            </div>
                            {activeOrNot === "Active" &&
                                <div className='estimated_delivery_time'><AccessTimeRoundedIcon /> Estimated Time : <span style={{ color: "#2C6F37" }}>{orderHistory.attributes.estimated_time}</span></div>}
                            <div className='order_status_container'>
                                <div
                                    className='order_status_heading'
                                    style={{
                                        color: orderStatus === "payment_failed" ? "#E11B23" : "inherit"
                                    }}>
                                    {orderHistory.attributes.status.replace(/_/g, ' ')}
                                </div>
                                <div className='order_status_images'>
                                    <ImageSeriesStatusOrderHistory
                                        order_type={orderHistory.attributes.order_type || ""} order_status={orderHistory.attributes.status} imageGroupDelivery={this.state.imageGroupDelivery}
                                        imageGroupCollection={this.state.imageGroupCollection} deliveryStatuses={this.state.orderStatusesDelivery}
                                        collectionStatuses={this.state.orderStatusesCollection} />
                                </div>
                            </div>
                            <div className='restaurant_action_button' >
                                <div className='items_rest_details'>
                                    <div className='items_details'><div className='items_details_heading' ><img src={imageNotes} alt='notes' /> Items</div> <div>{orderHistory.attributes.items_count}x</div></div>
                                    <div className='items_details' style={{ borderLeft: "2px solid #D4D4D4" }}><div className='items_details_heading' ><img src={imageRestaurantSign} alt='notes' /> Resaturant</div> <div>{orderHistory.attributes.restaurant}</div></div>
                                    <div className='items_details' style={{ borderLeft: "2px solid #D4D4D4" }}><div className='items_details_heading' ><img src={imageEuroSign} alt='notes' />Total Amount</div> <div>£{orderHistory.attributes.total}</div></div>
                                </div>
                                <div className='buttonContainerOrderHistory'>
                                {activeOrNot === "Active" ? (
                                <Button onClick={() => this.openTask(orderHistory.id,orderHistory.attributes.status,orderHistory.attributes.estimated_time)} className="reorderButton">
                                    TRACK ORDER
                                </Button>
                            ) : (
                                (orderHistory.attributes.status === "collected" || orderHistory.attributes.status === "delivered") ? (
                                    <Button onClick={() => this.reorderItems(orderHistory.id)} className="reorderButton">
                                        REORDER
                                    </Button>
                                ) : null
                            )}
                            <Button onClick={() => this.openViewOrderDetails(orderHistory.id)} className='viewDetailsButton'>VIEW DETAILS</Button>
                                </div>
                            </div>
                        </div>
                    )
                }
                )}
            </div>
        )
    }

    render() {
        return (
            <>
            <Profile navigateToPages={this.navigateToPage}>
                <div className="gift_card_panel">
                    <Search navigation={this.props.navigation} id="search" token={this.state.token} setOrderDetails={this.setSearchOrderHistoryData} />
                    {this.state.message?
                    <div className='no_result_search_found'>
                    <img src={noOrderHistoryResultFound} alt="no result found" />
                    <div style={{fontFamily:"Barmeno",display:"flex", flexDirection:"column",alignItems:"center", gap:"16px"}}>
                        <div className='heading_no_result'>No results found</div>
                        <div className='content_no_result'>Try searching for other order number or check your input.</div>
                    </div>
                </div>:
                    <>
                    {this.accordianToBeshown("Active",this.state.active_orders)}
                    {this.accordianToBeshown("Past",this.state.past_orders)}
                    </>
    }
                </div>
            </Profile>               
             <DialogPopupTasks className='dialog_wh' closeIcon={true} open={this.state.trackOrder} onClose={this.closeTask}>
                <Task id={this.state.id} token={this.state.token} orderStatus={this.state.orderStatusTosend} orderType={this.state.orderType} 
                    imageGroupCollection={this.state.imageGroupCollection} 
                    imageGroupDelivery={this.state.imageGroupDelivery} orderStatusesDelivery={this.state.orderStatusesDelivery} estimatedTimeTosend={this.state.estimatedTimeTosend}
                    orderStatusesCollection={this.state.orderStatusesCollection}
                     navigation={this.props.navigation} >
                </Task>
            </DialogPopupTasks>
            <DialogPopupTasks open={this.state.viewOrderDetailsOpen} onClose={this.closeViewOrderDetails}>
                <ViewOrderDetails OrderDetails={this.state.OrderDetails} formatDate={formatDateandTime} getInvoiceDataDownload={this.downloadInvoice}/>
            </DialogPopupTasks>
            </>
        )
    }

}
