import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { closeConnection, connection } from "../../../../../components/src/Dashboard/WebSockets";
import { storeAdminConfig } from "../../../../../components/src/Dashboard/WebSockets/StoreAdmin";

export const configJSON = require("./config");

export type OrderStatistics = {
    current_date: string,
    last_7_days_orders: number,
    week_before_last_7_days_orders: number,
    total_change: number,
    percentage_change: number,
    daily_completed_orders: { date: string; order_count: number; day: string }[];
}

export interface RevenueRecord {
    payment_method: string;
    total_amount: string | number;
    success_count: number;
    success_amount: string | number;
    cancel_count: number;
    cancel_amount: number;
  }
  
export interface OrderRevenue {
    current_date: string;
    last_7_days_revenue: RevenueRecord[];
    total_last_7_days_revenue: string | number;
    total_previous_week_revenue: number;
    percentage_change: number;
  }

export type Data = {
    active_orders: any,
    new_orders_count: number,
    total_orders_count: number,
    completed_orders_count: number,
    percentage_change_in_total_orders: number,
    percentage_change_in_completed_orders: number
}

export interface Props {
    navigation: any;
    role: string;
}

export interface S {
    token: string;
    data: Data;
    orderType: "collection" | "delivery";
    orderStatistics: OrderStatistics;
    orderRevenue: OrderRevenue;
    actionMenuAnchorEl: HTMLElement | null;
    printDialog: boolean;
    receiptText: string;
    printerName: string;
    cupsServerIP: string;
    printReceiptResponse: string;
}

export interface SS {}

export default class StoreAdminController extends BlockComponent<Props, S, SS> {
    orderStatisticsCallId: string = "";
    orderRevenueCallId: string = "";
    printReceiptApiCallId: string = "";
    subscription: any | null = null;
    mockData = [
        {
            "id": "496",
            "type": "order_history",
            "attributes": {
                "id": 496,
                "order_number": "0006-0202240923-0496",
                "placed_at": "2024-10-07T05:51:43.253+01:00",
                "order_type": "delivery",
                "status": "placed",
                "payment_source": "card",
                "items_count": 4,
                "restaurant": "Test Restaurant",
                "total": "15.0",
                "delivery_addresses": [
                    {
                        "id": 121,
                        "account_id": 612,
                        "address": "Address",
                        "name": "Akshay",
                        "flat_no": null,
                        "zip_code": "Ates",
                        "phone_number": "+441234567890",
                        "deleted_at": null,
                        "latitude": null,
                        "longitude": null,
                        "residential": true,
                        "city": "Test city",
                        "state_code": null,
                        "country_code": null,
                        "state": null,
                        "country": "GB",
                        "address_line_2": null,
                        "address_type": "Home",
                        "address_for": "shipping",
                        "is_default": true,
                        "landmark": null,
                        "created_at": "2024-09-11T12:02:17.941+01:00",
                        "updated_at": "2024-09-11T12:02:17.941+01:00"
                    }
                ],
                "reward_points": null,
                "estimated_time": "45 mins",
                "restaurant_detail": {
                    "id": 6,
                    "title": "Test Restaurant",
                    "location": "G8XW+HM2 Noida, Uttar Pradesh, India",
                    "contact": "0123456789",
                    "open_time": "2000-01-01T10:00:00.000+00:00",
                    "close_time": "2000-01-01T22:00:00.000+00:00",
                    "postcode": "201303",
                    "created_at": "2024-04-29T17:18:41.424+01:00",
                    "updated_at": "2024-10-03T16:37:08.302+01:00",
                    "order_type": [
                        "delivery",
                        "collection"
                    ],
                    "tax_reg_no": "121212121212",
                    "estimated_delivery_time": 45,
                    "estimated_collection_time": 30,
                    "latitude": "28.548956570192235",
                    "longitude": "77.34663983260744"
                },
                "otp": 1188
            }
        }
    ];

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            token: "",
            data: {
                active_orders: [],
                new_orders_count: 0,
                total_orders_count: 0,
                completed_orders_count: 0,
                percentage_change_in_total_orders: 0,
                percentage_change_in_completed_orders: 0
            },
            orderType: "delivery",
            orderStatistics: {
                current_date: "",
                last_7_days_orders: 0,
                week_before_last_7_days_orders: 0,
                total_change: 0,
                percentage_change: 0,
                daily_completed_orders: []
            },
            orderRevenue: {
                current_date: "",
                last_7_days_revenue: [],
                total_last_7_days_revenue: 0,
                total_previous_week_revenue: 0,
                percentage_change: 0
            },
            actionMenuAnchorEl: null,
            printDialog: false,
            receiptText: "",
            printerName: "",
            cupsServerIP: "",
            printReceiptResponse: "",
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        await this.getToken();
        this.getOrderStatistics();
        this.getOrderRevenue();
        this.subscribe();
    }

    async componentWillUnmount() {
        this.unsubscribe();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (prevState.orderType !== this.state.orderType) {
            this.getOrderRevenue();
        }
    }

    subscribe = () => {
        this.subscription = connection(this.state.token);

        storeAdminConfig({
            subscription: this.subscription,
            setDashboardData: (data: any) => this.setState({ data: data }),
        })
    }

    unsubscribe = () => {
        if (this.subscription) closeConnection(this.subscription);
    }

    getToken = async () => {
        const token = localStorage.getItem("authToken") || "";
        this.setState({ token });
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        const messageHandlers = {
            [this.orderStatisticsCallId]: () => {
                const data = apiResponse;
                if (data) {
                    data.daily_completed_orders = data.daily_completed_orders.map((order: any) => {
                        const date = new Date(order.date);
                        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                        order.day = days[date.getDay()];
                        return order;
                    });
                    this.setState({ orderStatistics: data });
                }
            },
            [this.orderRevenueCallId]: () => {
                const data = apiResponse;
                console.log("running order revenue call back", data);
                if (data) {
                    this.setState({ orderRevenue: data });
                }
            },
            [this.printReceiptApiCallId]: () => {
                const data = apiResponse;
                if (data.status) {
                    this.setState({
                        printReceiptResponse: data.message,
                    });

                    setTimeout(() => {
                        this.setState({
                            printReceiptResponse: "",
                        });
                    }, 5000);
                }
            }
        }

        const handler = messageHandlers[callId];
        if (handler) handler();
        if (errorMessage) runEngine.debugLog("API Error", errorMessage);
    }

    handlePrint = () => {
        const printData = {
            receipt: this.state.receiptText,
            printer_name: this.state.printerName,
            cups_server: this.state.cupsServerIP,
        };

        const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.printReceiptApiCallId = getDataMsg.messageId;
        getDataMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.printReceiptAPI.endPoint
        );
        getDataMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": configJSON.printReceiptAPI.contentType,
            })
        );
        getDataMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(printData)
        );
        getDataMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.printReceiptAPI.method
        );
        runEngine.sendMessage(getDataMsg.id, getDataMsg);
    }

    getOrderStatistics = () => {
        const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.orderStatisticsCallId = getDataMsg.messageId;

        getDataMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.orderStatistics.endPoint
        );
        
        getDataMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": configJSON.contentType,
                token: this.state.token,
            })
        );
        
        getDataMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.orderStatistics.method
        );
        
        runEngine.sendMessage(getDataMsg.id, getDataMsg);
    }

    getOrderRevenue = () => {
        const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.orderRevenueCallId = getDataMsg.messageId;

        getDataMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.orderRevenue.endPoint + "?order_type=" + this.state.orderType
        );
        
        getDataMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": configJSON.contentType,
                token: this.state.token,
            })
        );
        
        getDataMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.orderRevenue.method
        );
        
        runEngine.sendMessage(getDataMsg.id, getDataMsg);
    }
}