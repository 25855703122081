import {view1, view2, view3} from "./../../blocks/catalogue/src/assets";
import { addMinutes, endOfDay, isValid, isSameDay, parse, format, startOfDay } from 'date-fns';
import cardValidator from "card-validator"

export function scrollTop(item: string) {
    const parentDiv = document.getElementById("termsCondition");
        if (!parentDiv) return;
        const childDiv = parentDiv.querySelector(`#${item.replace(/&/g, '').split(" ").join("")}`);
        if (!childDiv) return;
            
        const parentRect = parentDiv.getBoundingClientRect();
    const childRect = childDiv.getBoundingClientRect();
  
    // Calculate the scroll position to bring the child to the top
    const scrollPosition = childRect.top - parentRect.top + parentDiv.scrollTop;
  
    // Adjust scroll position if child is below the visible area
    if (scrollPosition + childRect.height > parentRect.height) {
      // Scroll up by the difference between the child's height and the parent's height
      parentDiv.scrollTop = scrollPosition - (parentRect.height - childRect.height);
    } else {
      parentDiv.scrollTop = scrollPosition;
    }
  }

  export function borderLeftColor(selectedItem: string | null, category: string, i: number) {
    return (!selectedItem && (i === 0)) ? "#E11B22" : (selectedItem === category ? "#E11B22" : "#BFBFBF")
  } 

  export function fontWeight(selectedItem: string | null, category: string, i: number) {
    return (!selectedItem && (i === 0)) ? "bold" : (selectedItem === category ? "bold" : "normal")
  } 

  export function copyContent(val: string) {
    navigator.clipboard.writeText(val);
  }
  
  export function createNewArray(arr: any) {
    let resultMap = new Map();

    arr.forEach((obj: any, index: number )=> {
        if (resultMap.has(obj.attributes.sub_category)) {
            resultMap.get(obj.attributes.sub_category).sub_sub_categories.push({
                ...obj.attributes.sub_sub_category,
                ...obj.attributes.images,
                    restaurant_detail: obj.attributes.restaurant,
                    serves: obj.attributes.sub_sub_category.serves,
                    favourited: obj.attributes.favourited,
                    product_type: obj.attributes.sub_sub_category.product_type,
                    id: obj.id,
                    price: obj.attributes.on_its_own_price
                // Add other properties of sub_sub_category if needed
            });
        } else {
            resultMap.set(obj.attributes.sub_category, {
                sub_category: obj.attributes.sub_category,
                restaurant_detail: obj.attributes.restaurant,
                id: obj.id,
                type: obj.type,
                attributes: obj.attributes,
                bgImg: [view1,view2,view3],
                sub_sub_categories: [{
                    ...obj.attributes.sub_sub_category,
                    ...obj.attributes.images,
                    serves: obj.attributes.sub_sub_category.serves,
                    favourited: obj.attributes.favourited,
                    product_type: obj.attributes.sub_sub_category.product_type,
                    id: obj.id,
                    price: obj.attributes.on_its_own_price
                    // Add other properties of sub_sub_category if needed
                }]
            });
        }
    });

    return Array.from(resultMap.values());
}

export function itemsList(data: any[], id: number, fav: boolean) {
  data.forEach(mainItem => {
    mainItem.sub_sub_categories.forEach((subItem: any) => {
      if (subItem.id === id) {
        subItem.favourited = fav;
      }
    });
  });
  return data;
}
 
function clearNumber (value = '') {
  return value.replace(/\D+/g, '')
}

export function formatCreditCardNumber(value:string) {
  if (!value) {
    return {cardNumber:value,length:19} ;
  }

  const clearValue = clearNumber(value);
  const cardBrand = cardValidator.number(value);
  let formattedValue = ""
  let expectedLength = 19
  let cardBrands = ""
  if(!cardBrand.card){
    formattedValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
      4,
      8
    )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`
  } else{
  switch (cardBrand.card.type) {
    case 'visa':
      case 'mastercard':
      case 'discover':
      case 'unionpay':
        if( cardBrand.card.type === "unionpay"){
          formattedValue += `${clearValue.slice(0, 4)} ${clearValue.slice(
            4,
            8
          )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)} ${clearValue.slice(16, 19)}`;
          expectedLength = 23;
        } else{
          formattedValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
            4,
            8
          )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;
          expectedLength = 19;
        }
        cardBrands=cardBrand.card.type
        break;
      // case 'diners-club':
      //   formattedValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
      //     4,
      //     8
      //   )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      //   expectedLength = 19;
      //   cardBrands=cardBrand.card.type
      case 'american-express':
        formattedValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 15)}`;
        expectedLength = 17
        cardBrands=cardBrand.card.type
        break;
    default:
      formattedValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`
      expectedLength = 19
      cardBrands=cardBrand.card.type
  }
}

  return {cardNumber:formattedValue.trim(), length: expectedLength, cardBrand: cardBrands};
}

export function formatCVC (value:any, length?:number) {
  const clearValue = clearNumber(value)
  let maxLength = length

  return clearValue.slice(0, maxLength)
}


export function formatExpirationDate(value: string): {expiry:string, error:string} {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 4) {
    const month = clearValue.slice(0, 2);
    const year = clearValue.slice(2, 4);

    const monthNumber = parseInt(month, 10);
    const currentYear = new Date().getFullYear() % 100; // Get last two digits of current year
    const currentMonth = new Date().getMonth() + 1; // Get current month (0-11, so add 1)

    const formattedMonth = Math.min(Math.max(monthNumber, 1), 12).toString().padStart(2, '0');
    
    const expirationYear = parseInt(year, 10);
    
    if (expirationYear < currentYear || (expirationYear === currentYear && monthNumber <= currentMonth)) {
      return {expiry:`${formattedMonth}/${year}`, error:'Expiration date must be in the future'};
    }
    
    return {expiry:`${formattedMonth}/${year}`, error:''};
  }
  return {expiry:`${clearValue}`, error:''}
}

export function formatDate(dateString:string) {
  const date = new Date(dateString);
  
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  return date.toLocaleString('en-GB', options).replace(',', ' at')
}

export const decodeJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const recallInterval = (activeSection: string) => {
  const selectedId: any = document.getElementById(activeSection);
  let intervalId: any = null;
  if(!selectedId) {
    intervalId = setInterval(() => {
      clearInterval(intervalId);
      recallInterval(activeSection)
    }, 1000)
  } else {
    clearInterval(intervalId);
    selectedId.scrollIntoView({
      block:'center',
      behavior: 'smooth',
    })
  }
}

type ConditionFunction<T> = (value: T[keyof T], key: keyof T) => boolean;

export const removeProperties = <T extends object>(obj: T, condition: ConditionFunction<T>): Partial<T> => {
  return Object.keys(obj).reduce((acc, key) => {
    const typedKey = key as keyof T;
    if (!condition(obj[typedKey], typedKey)) {
      acc[typedKey] = obj[typedKey];
    }
    return acc;
  }, {} as Partial<T>);
}

const convertTo24HourFormat = (time12h: string) => {
  // Create a new Date object and set the hours and minutes based on the 12-hour time format
  const [time, modifier] = time12h.split(' '); // Split time and period (AM/PM)
  let [hours, minutes] = time.split(':'); // Split hours and minutes

  // Convert hours to number and adjust for PM if necessary
  if (hours === '12') {
    hours = '00'; // Special case for 12 AM
  }
  if (modifier === 'PM') {
    hours = (parseInt(hours, 10) + 12).toString(); // Convert PM hours to 24-hour format
  }

  // Ensure the hours and minutes are in two-digit format
  hours = hours.padStart(2, '0');
  minutes = minutes.padStart(2, '0');

  // Return the time in 24-hour format
  return {hours, minutes};
}

export const generateTimeSlots = (date: Date, openTime: string, closeTime: string): string[] => {
  const times: string[] = [];

  const generateSlots = (startDate: Date, endDate: Date) => {
    let currentTime = startDate;
    currentTime.setMinutes(Math.ceil(currentTime.getMinutes() / 30) * 30, 0, 0);
    for (currentTime; currentTime <= endDate; currentTime = addMinutes(currentTime, 30)) {
      const hourString = format(currentTime, 'hh');
      const minuteString = format(currentTime, 'mm');
      const period = format(currentTime, 'aa');
      times.push(`${hourString}:${minuteString} ${period}`);
    }
  };
     const {hours: open_hours, minutes: open_minutes} = convertTo24HourFormat(openTime);
     const {hours: close_hours, minutes: close_minutes} = convertTo24HourFormat(closeTime);

  const open_dt = new Date(date);
  open_dt.setHours(Number(open_hours), Number(open_minutes), 0, 0);
  const close_dt = new Date(date);
  close_dt.setHours(Number(close_hours), Number(close_minutes), 0, 0);
  generateSlots(open_dt, close_dt);

  return times;
};

export const convertToDate = (dateString: string): Date | null => {
  const parsedDate = parse(dateString, 'dd/MM/yyyy', new Date());
  const now = new Date();
  const offset = getLondonOffset() * 3600000;
  if(isSameDay(parsedDate, now)) {
    return new Date(now.getTime() + offset);
  } else {
    const londonOffsetDate = new Date(parsedDate.getTime() + offset);
    return isValid(londonOffsetDate) ? londonOffsetDate : null;
  }
};

export const capitalizeFirstLetter = (word: string) => {
  if (!word) return word; // Check if word is empty or undefined
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const getLondonOffset = () => {
  const now = new Date();
  const formatter = new Intl.DateTimeFormat("en-GB", {
    timeZone: "Europe/London",
    hour12: false,
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  });

  const parts = formatter.formatToParts(now);
  const londonYear = parts.find(part => part.type === "year")?.value;
  const londonMonth = parts.find(part => part.type === "month")?.value;
  const londonDay = parts.find(part => part.type === "day")?.value;
  const londonHour = parts.find(part => part.type === "hour")?.value;
  const londonMinute = parts.find(part => part.type === "minute")?.value;
  const londonSecond = parts.find(part => part.type === "second")?.value;

  const londonTime = new Date(`${londonYear}-${londonMonth}-${londonDay}T${londonHour}:${londonMinute}:${londonSecond}Z`);
  const offset = (londonTime.getTime() - now.getTime()) / 3600000; // Convert milliseconds to hours
  return offset;
}

export const londonDateTime = () => {
  const now = new Date();
  const offsetInMilliseconds = getLondonOffset() * 3600000;
  const adjustedDate = new Date(now.getTime() + offsetInMilliseconds);
  return adjustedDate;
}

export const containsIds = (deal_products: any[]): boolean => {
  const filteredList = deal_products.filter((deal_product: {attributes: {dealable: {title: string, id: number},dealable_type: string}}) => deal_product.attributes.dealable_type.toLowerCase() === "BxBlockCatalogue::foodcategory")
  return filteredList.length === 2;
};

export const dealableProducts = (responseJson: any, getDynamicDealProduct: Function) => {
  responseJson.data?.attributes.deal_products?.forEach((deal_product: {attributes: {dealable: {title: string, id: number},dealable_type: string}}, index: number) => {
      getDynamicDealProduct(deal_product.attributes.dealable.id);
  })
}

export const formatDateandTime =(dateString:string)=>{
  const date = new Date(dateString);

  const options = {
    weekday: 'short' as 'short',
    year: 'numeric' as 'numeric',
    month: 'short' as 'short',
    day: 'numeric' as 'numeric',
    hour: '2-digit' as '2-digit',
    minute: '2-digit' as '2-digit',
    hour12: true as true,
    timeZone: 'Europe/London' as 'Europe/London'
  };

  return date.toLocaleString('en-GB', options);
}

export const showDips = (item: any): string => {
  if (item.attributes.toppings && item.attributes.toppings.length > 0) {
    const dips = item.attributes.toppings.filter((topping: any) => topping.category_type === 'Dips');
    if (dips.length > 0) {
      return dips.map((dip: any) => dip.name).join(', ');
    }
  }
  
  return '';
}

export function clearStorage() {
  localStorage.clear();
  sessionStorage.clear();
}
export const validatePhoneNumber = (phone: string, countryCode: string): boolean => {
  const phoneLength = phone.length - countryCode.length;
  let validLength: number;

  switch (countryCode) {
    case '44': // UK
      validLength = 10;
      break;
    case '91': // India
      validLength = 10;
      break;
    case '1': // US
      validLength = 10;
      break;
    default:
      return false;
  }

  return phoneLength === validLength;
}