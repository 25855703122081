Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
exports.socialLoginAPiEndPoint = "bx_block_apple_login/google_auth";
exports.validationApiContentType = "application/json";
exports.socialLoginAPiMethod = "POST";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.httpPostMethod = "POST";
exports.apiContentType = "application/json";
exports.getAppleSignInApiEndPoints = "bx_block_apple_login/validate";
exports.errorTitle = "Error";

exports.errorMessageAppleLogin = "Apple Login - Invalid Credential";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AppleLogin";
exports.labelBodyText =
  "\nThis is your Apple login, here users log in using their Apple account details.\n";
exports.btnExampleTitle = "CLICK ME";
exports.noInternetAlertMessage = "Please check your internet connection";
exports.appleLoginButtonText = "Sign in with Apple";
exports.appleLogoutButtonText = "Sign up with Apple";
exports.appleButtonText = "Continue with Apple";
exports.btnSignInWithApple = "Sign In With Apple Login";
exports.alertTitle = "Apple Login";
exports.apiErrorMessage = "Error received from API response. Error message: ";
exports.signInSuccessfully = "User Signed in Successfully in app.";
exports.okay = "Okay";
exports.appleAuthErrorCANCELED = "The user canceled the authorization attempt.";
exports.appleAuthErrorINVALID_RESPONSE =
  "The authorization request received an invalid response.";
exports.appleAuthErrorNOT_HANDLED = "The authorization request wasn't handled.";
exports.appleAuthErrorFAILED = "The authorization attempt failed.";
exports.appleAuthErrorUNKNOWN =
  "The authorization attempt failed for an unknown reason.";
exports.unknownError = "Unknown error";
exports.storageKeyUserId = "userId";
exports.storageKeyIdentityToken = "identityToken";
exports.userSuccessfullyLogin = "User successfully login";
exports.clientID =
  "972727209319-f76v6ma864r8f9qerh59685qs9f0j4pj.apps.googleusercontent.com";
