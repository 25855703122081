import React from "react";
// Customizable Area Start
import Rolesandpermissions2 from "../../rolesandpermissions2/src/Rolesandpermissions2.web";
import Sidebar from "../../../components/src/Dashboard/Sidebar/Sidebar";
import Header from "../../../components/src/Dashboard/Header/Header";
import StoreAdminDashboard from "./dashboards/StoreAdminDashboard/StoreAdminDashboard";
import "./Dashboard.css";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getRoleDashboard = (role: string | null) => {
    switch (role) {
      case "InStoreOperator":
        return <StoreAdminDashboard navigation={this.props.navigation} role={role} />;
      case "InStoreManager":
        return <StoreAdminDashboard navigation={this.props.navigation} role={role} />;
      case "Chef":
        return <div>Chef Dashboard</div>;
      case "OnlineOrderManager":
        return <div>Online Order Manager Dashboard</div>;
      default:
        return null;
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const role = new URLSearchParams(window.location.search).get("role");

    return (
      <div data-testid="dashboard-wrapper" className="dashboard__wrapper">
        <aside data-testid="dashboard-sidebar" className="dashboard__sidebar">
          <Sidebar navigation={this.props.navigation} userDetails={this.state.userDetail} />
        </aside>

        <main data-testid="dashboard-main" className="dashboard__main">
          <Header navigation={this.props.navigation} selectedRole={role} roles={this.state.userDetail.roles ?? []} userDetails={this.state.userDetail} selectedDate={new Date().toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} />
          {this.getRoleDashboard(role)}
        </main>

        <Rolesandpermissions2 id="Popup" navigation={this.props.navigation} />
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
