import React from "react";
import {
    customerManagementIcon,
    dashboardIcon,
    deliveryAreasIcon,
    locationIcon,
    logo,
    logoutIcon,
    newOrderIcon,
    orderManagementIcon,
    settingsIcon,
    staffInformationIcon,
    statisticsAndReportsIcon,
    supportIcon,
    trackDriverIcon
} from "../../../../blocks/dashboard/src/assets";
import LogoutPopup from "../../../../blocks/settings2/src/LogoutPopup.web";
import "./Sidebar.css";

interface Props {
    navigation: any;
    userDetails?: any;
}

interface S {
    logOut: boolean;
}

export default class Sidebar extends React.Component<Props, S> {
    constructor(props: Props) {
        super(props);

        this.state = {
            logOut: false
        }
    }

    handleNavigation = (href: string) => {
        location.replace(href);
    }

    logout = () => {
        this.setState({ logOut: true });
    }

    render() {
        const { userDetails } = this.props;
        const role = new URLSearchParams(window.location.search).get("role");
        const navMenu = [
            { name: "Dashboard", icon: dashboardIcon, href: `/Dashboard?role=${role}` },
            { name: "New Order", icon: newOrderIcon, href: `/NewOrderCreation?role=${role}` },
            { name: "Customer Management", icon: customerManagementIcon, href: `/Dashboard/Customer-Management?role=${role}` },
            { name: "Order Management", icon: orderManagementIcon, href: `/Dashboard/Order-Management?role=${role}` },
            { name: "Staff Information", icon: staffInformationIcon, href: `/Dashboard/Staff-Information?role=${role}` },
            { name: "Track Driver", icon: trackDriverIcon, href: `/Dashboard/Track-Driver?role=${role}` },
            { name: "Delivery Areas", icon: deliveryAreasIcon, href: `/Dashboard/Delivery-Areas?role=${role}` },
            { name: "Statistics & Reports", icon: statisticsAndReportsIcon, href: `/Dashboard/Statistics-Reports?role=${role}` },
        ];

        const sidebarWrapper = document.querySelector(".sidebar__navbar");
        if (sidebarWrapper) {
            if (sidebarWrapper.scrollHeight > sidebarWrapper.clientHeight) {
                sidebarWrapper.classList.add("scrollbar__shadow");
            } else {
                sidebarWrapper.classList.remove("scrollbar__shadow");
            }
        }

        return (
            <div className="sidebar__wrapper">
                <div className="sidebar__logo_wrapper">
                    <img src={logo} alt="logo" className="sidebar__logo" />
                </div>
                
                <nav className="sidebar__navbar">
                    {navMenu.map((item, index) => {
                        const isActive = `${window.location.pathname}?role=${role}` === item.href;
                        return (
                            <div key={index} className="nav__item" onClick={() => this.handleNavigation(item.href)}>
                                {isActive && <div className="nav__active" />}
                                <img src={item.icon} alt="icon" className="nav__item__icon" />
                                <span className="nav__item__label">{item.name}</span>
                            </div>
                        )
                    })}
                </nav>

                <div className="sidebar__footer">
                    <div className="sidebar_footer__item location">
                        <img src={locationIcon} alt="icon" className="sidebar_footer__icon" />
                        <span className="sidebar_footer__label">{userDetails?.restaurant?.title} - {userDetails?.restaurant?.postcode}</span>
                    </div>

                    <div className="sidebar_footer__item" onClick={() => this.handleNavigation("/dashboard/support")}>
                        <img src={supportIcon} alt="icon" className="sidebar_footer__icon" />
                        <span className="sidebar_footer__label">Contact & Support</span>
                    </div>

                    <div className="sidebar_footer__item" onClick={() => this.handleNavigation("/dashboard/settings")}>
                        <img src={settingsIcon} alt="icon" className="sidebar_footer__icon" />
                        <span className="sidebar_footer__label">Settings</span>
                    </div>

                    <div className="sidebar_footer__item logout" onClick={this.logout}>
                        <img src={logoutIcon} alt="icon" className="sidebar_footer__icon" />
                        <span className="sidebar_footer__label">Log Out</span>
                    </div>
                </div>

                <LogoutPopup open={this.state.logOut} onClose={() => this.setState({ logOut: false })} />
            </div>
        );
    }
}