interface StoreAdminConfigProps {
    subscription: WebSocket | null;
    setDashboardData: (data: any) => void;
}

export const storeAdminConfig = ({ subscription, setDashboardData }: StoreAdminConfigProps) => {
    if (subscription) {
        const socket = subscription

        socket.onopen = () => {
            console.log('WebSocket connection established');
            const message = {
                command: "subscribe",
                identifier: JSON.stringify({
                    channel: "BxBlockOrderManagement::OrderToStoreManageChannel",
                })
            };

            socket.send(JSON.stringify(message));
        };

        socket.onmessage = (event: any) => {
            const response = event.data;
            const message = JSON.parse(response);

            if (message.type === 'ping') return;
            if (message.message && message.message.data) setDashboardData(message.message.data);

            console.log('WebSocket message received:', message);
        };

        socket.onerror = (error: any) => {
            console.error('WebSocket error:', error);
        }
    }
}

export const send = (ws: WebSocket, message: any) => {
    ws.send(JSON.stringify(message));
}