Object.defineProperty(exports, "__esModule", {
    value: true
});

exports.contentType = "application/json";
exports.orderStatistics = {
    endPoint: "bx_block_dashboard/store_manage_dashboard/order_statics",
    method: "GET"
};
exports.orderRevenue = {
    endPoint: "bx_block_dashboard/store_manage_dashboard/order_revenue",
    method: "GET"
};
exports.printReceiptAPI = {
    method: "POST",
    endPoint: "bx_block_receiptcustomisation/print_receipts",
    contentType: "application/json"
};